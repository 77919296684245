import React, { useEffect, useState } from "react";
import "./WorklistScreenComponent.css";
interface Props {
  approvals?: any;
  requestor?: any;
  memoDetail: any;
}

export const FlowCardComponent = (props: Props) => {
  // const [flowCardData, setFlowCardData] = useState()
  const [seq, setSeq] = useState<any>();
  const [current, setCurrent] = useState<any>();

  const highlight = () => {
    if (props.memoDetail) {
      setCurrent(
        props.memoDetail.requestDetails.memoDetail.current_approval_level
      );
      if (current) {
        props.memoDetail.requestDetails.listApprovalDetails.map((e: any) => {
          if (current === e.sequence) {
            setSeq(e.sequence);
          }
        });
      }
    }
  };

  useEffect(() => {
    highlight();
  }, [current, props.memoDetail.memoid]);
  return (
    <>
        <div className={`flow-card-container ${props.approvals && props.approvals.sequence === seq  ? "highlight" : ""}`} >
          <div className="img-flow-card">
            <div className="circle-text">
              {props.approvals && (
                <p className="text-profile">
                  {props.approvals.approver &&
                    props.approvals.approver.nameEn
                      .substring(0, 2)
                      .toUpperCase()}
                </p>
              )}
              {props.requestor && (
                <p className="text-profile">
                  {props.requestor &&
                    props.requestor.NameEn.substring(0, 2).toUpperCase()}
                </p>
              )}
            </div>
          </div>
          <div className="flow-card-detail">
            <div className="text-user-name">
              {props.approvals && (
                <>
                  <p className="user-name">{props.approvals.approver.nameEn}</p>
                  <p className="user-role">
                    {props.approvals.approver.positionNameEn}
                  </p>
                </>
              )}
              {props.requestor && (
                <>
                  <p className="user-name">{props.requestor.NameEn}</p>
                  <p className="user-role">{props.requestor.PositionNameEn}</p>
                </>
              )}
            </div>
            {/* change text color */}
            <p className="text-status highlight-text">
              {props.approvals && props.approvals.approver.departmentNameEn}
              {props.requestor && props.requestor.DepartmentNameEn}
            </p>
          </div>
          {props.approvals && (
            <div className="status-badge">
              <span>{props.approvals.signature_en}</span>
            </div>
          )}
        </div>
    </>
  );
};
