import {
  ILogicTypeHideControl,
  ILogicTypeReadOnly,
} from "../IRequestModel/ILogicModel";
import { LogicOperatorType, LogicType } from "../constants/logic-type";
export type advanceFormItemType = {
  label: string;
  type: string;
  items?: string[];
  columns?: { label: string; type: string; isInTable: boolean }[];
};

export const enumToObject = (
  emun: Record<string, string>
): { [key: string]: string } => {
  const readableLabels: { [key: string]: string } = Object.fromEntries(
    Object.entries(emun).map(([key, value]) => [
      value,
      key.replace(/([a-z])([A-Z])/g, "$1 $2"),
    ])
  );
  return readableLabels;
};

export const getTranFormAdvanceForm = (advanceForm: {
  items: any[];
}): advanceFormItemType[] => {
  let result: {
    label: string;
    type: string;
    items?: string[];
    columns?: any[];
  }[] = [];
  advanceForm.items.forEach((item) => {
    const layout = item.layout;
    layout.forEach((control: any) => {
      const temp = control.template;
      if (temp.type === "l" || temp.type === "em") {
        return;
      }

      if (temp.type === "dd") {
        result.push({
          label: temp.label,
          type: temp.type,
          items: temp.attribute.items.map((e: any) => e.item),
        });
      } else if (temp.type === "tb") {
        result.push({
          label: temp.label,
          type: temp.type,
          columns: temp.attribute.column.map((e: any) => {
            return {
              label: e.control.template.label,
              type: e.control.template.type,
            };
          }),
        });
      } else {
        result.push({ label: temp.label, type: temp.type });
      }
    });
  });

  return result;
};

export const checkIsCorrect = (
  aValue: string | number,
  operator: LogicOperatorType,
  bValue: string | number
): boolean => {
  switch (operator) {
    case LogicOperatorType.Equal:
      return aValue === bValue;
    case LogicOperatorType.MoreThan:
      return Number(aValue) > Number(bValue);
    case LogicOperatorType.LessThan:
      return Number(aValue) < Number(bValue);
    case LogicOperatorType.GreaterThanAndEqual:
      return Number(aValue) >= Number(bValue);
    case LogicOperatorType.LessThanAndEqual:
      return Number(aValue) <= Number(bValue);
    case LogicOperatorType.NotEqual:
      return aValue !== bValue;
    case LogicOperatorType.Contain:
      return String(aValue).includes(String(bValue));
    case LogicOperatorType.StartWith:
      return String(aValue).startsWith(String(bValue));
    case LogicOperatorType.EndWith:
      return String(aValue).endsWith(String(bValue));
    default:
      return false;
  }
};

export const processHideControlLogic = (
  jsonValue: ILogicTypeHideControl | ILogicTypeReadOnly,
  _control: any,
  updateTo: string,
  controlValue?: any
) => {
  jsonValue.conditions.forEach((con) => {
    let isConditionSatisfied = false;

    let checkValue = controlValue ? controlValue : con.value;
    console.log(con);

    if (con.comparewith) {
      _control.forEach((control: { layout: any[] }) => {
        control.layout.forEach((layout) => {
          if (layout.template.label === con.comparewith) {
            checkValue = layout.data.value;
          }
        });
      });
      console.log(checkValue);
    }
    _control.forEach((control: { layout: any[] }) => {
      control.layout.forEach((layout) => {
        if (layout.template.label === con.field.label) {
          isConditionSatisfied = checkIsCorrect(
            checkValue,
            con.operator,
            layout.data.value
          );
        }
      });
    });
    const objLabel: { label: string; type: string; isInTable: string } =
      JSON.parse(jsonValue.label);
    _control.forEach((control: { layout: any[] }) => {
      control.layout.forEach((layout: any) => {
        if (objLabel.isInTable && objLabel.isInTable !== "") {
          if (objLabel.isInTable === layout.template.label) {
            layout.template.attribute.column.forEach((col: any) => {
              if (col.label === objLabel.label) {
                if (updateTo === "isShow") {
                  col.isShow = !isConditionSatisfied;
                } else {
                  col.isReadOnly = con.field.isInTable
                    ? con
                    : isConditionSatisfied;
                }
              }
            });
          }
        } else {
          if (layout.template.label === objLabel.label) {
            if (updateTo === "isShow") {
              layout.isShow = !isConditionSatisfied;
            } else {
              layout.isReadOnly = isConditionSatisfied;
            }
          }
        }
      });
    });
  });
};
