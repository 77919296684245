import React, { useEffect, useState } from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { sorterFunc } from "../../Helper/SortingFunction";

type Props = {
  onClick: (type: "dec" | "asc" | null) => void;
  sortType?: "dec" | "asc" | null;
};

const SortingButton = ({ sortType, onClick }: Props) => {
  const callSortFunc = () => {
    let sort = sortType;
    switch (sortType) {
      case "asc":
        sort = "dec";
        break;
      case "dec":
        sort = null;
        break;
      default:
        sort = "asc";
    }
    onClick(sort);
  };

  return (
    <div className="sort-icon" onClick={callSortFunc}>
      {sortType === "asc" ? (
        <AiOutlineSortAscending width={"100%"} fontSize={20} />
      ) : sortType === "dec" ? (
        <AiOutlineSortDescending width={"100%"} fontSize={20} />
      ) : (
        <AiOutlineSortAscending width={"100%"} fontSize={20} opacity={0.3} />
      )}
    </div>
  );
};

export default SortingButton;
