import React, { useState, useEffect, useMemo, useRef } from "react";
import "./WorkListScreen.css";
import "../../components/WorklistScreenComponent/WorklistCardComponent.css";
import { IoMenu } from "react-icons/io5";
import { Button, InputNumber, Pagination } from "antd";
import { DetailContentScreen } from "./DetailContentScreen";
import WorkListSideBarElementUse from "./WorkListSideBarElementUse";
import { WorklistCardComponent } from "../../components/WorklistScreenComponent/WorklistCardComponent";
// import Pagination from "react-bootstrap/Pagination";
import { TreeSelectNewRequest } from "../../components/TreeSelectNewRequest/TreeSelectNewRequest";
import moment, { Moment } from "moment";
import { IMemoButtonModel } from "../../IRequestModel/IMemoButtonModel";
import { FooterComponents } from "../../components/FooterComponents/FooterComponents";
import { GetWorkListByTaskGroup } from "../../Services/WorklistService";
import { AnimatePresence, motion } from "framer-motion";
import {
  ActionMemoPage,
  GetButtonMemoByMemoId,
  GetAllMemo,
  GetMemoDetail,
} from "../../Services/MemoService";
import { GetAllHistory } from "../../Services/HistoryService";
import { GetAllAttachFiles } from "../../Services/AttachFileService";
import { GetAllApprovals } from "../../Services/ApprovalService";
import withPerMission from "../../components/HOC/withPermission";
import { IAutoNumberAttibute } from "../../IRequestModel/IAutoNumberFormat";
import { GetAutoNumber } from "../../Services/RequestControlService";
import useAlert from "../../hooks/useAlert";
import { useUserContext } from "../../Context/UserContext";
import {
  IGetWorklistRequest,
  ISearchWorklist,
  ITodo,
  IWorklistDetail,
  TodoType,
  WorklistHeaderType,
  WorklistSerachType,
} from "../../IRequestModel/IWorklistModel";
import { IUserModel } from "../../IRequestModel/IUserModel";
import { useTranslation } from "react-i18next";
import { MemoPageContextProvider } from "../../Context/MemoPageContext";
import useMemoPage from "../../hooks/useMemoPage";
interface Props {
  responeConfig: any;
}
const WorkListScreen = (props: Props) => {
  const [userData] = useUserContext();
  const { toggleAlert } = useAlert();

  //Worklist Data
  const [workListData, setWorkListData] = useState<ITodo[]>();
  const [dtWorkListData, setDTWorkListData] = useState<any>();
  const [worklistToShow, setWorklistToShow] = useState<ITodo[]>([]);

  //Filter Option
  const [formTypeOptions, setFormTypeOptions] = useState<string[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<string[]>([]);
  const [departmentsOptions, setDepartmentsOptions] = useState<string[]>([]);
  const [formStatusOptions, setFormStatusOptions] = useState<string[]>([]);

  //Filter data
  const [searchObject, setSearchObject] = useState<ISearchWorklist>({
    company: [],
    date: [],
    department: [],
    form: [],
    keyword: "",
    status: [],
  });
  const { t } = useTranslation(["translation"]);
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemPerPage, setItemPerPage] = useState<number>(5);
  const [buttonType, setButtonType] = useState("");

  //Toggle
  const [isShowPdfData, setIsShowPdfData] = useState(true);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [onDetailLoading, setOnDetailLoading] = useState<boolean>(false);
  const [toggleDetail, setToggleDetail] = useState<Boolean>(false);
  const [sidebarState, setSidebarState] = useState(false);

  const [currentMemoId, setCurrentMemoId] = useState<number>(0);
  const [taskHeader, setTaskHeader] = useState<string>("To Do List");
  const [currentIndex, setCurrentIndex] = useState<Number>(-1);
  const [selectedCard, setSelectedCard] = useState<IWorklistDetail>();
  const [menuButton, setMenuButton] = useState<any[]>([]);
  const [defaultTask, setDefaultTask] =
    useState<WorklistHeaderType>("To Do List");
  const [taskGourp, setTaskGourp] = useState<TodoType>("todo");
  const [getMemoDetail, setGetMemoDetail] = useState<any>();

  const { processQue, getMyMemoStatus, checkSyncMemo } = useMemoPage();
  const previousQue = useRef<number[]>([]);
  const abortController = useRef<AbortController | null>(null);

  //Default item per page
  const _itemPerPage = itemPerPage ? Number(itemPerPage) : 1;
  const indexOfLastPost = currentPage * _itemPerPage;
  const indexOfFirstPost = indexOfLastPost - _itemPerPage;
  useEffect(() => {
    window.onpopstate = (e) => {
      if (location.pathname === "/Default") {
        sessionStorage.setItem("action_back", "yes");
      }
    };

    window.onbeforeunload = (e) => {
      if (location.pathname === "/Default") {
        sessionStorage.removeItem("task");
        sessionStorage.removeItem("searchObject");
        sessionStorage.removeItem("page");
        sessionStorage.removeItem("selectedCard");
        sessionStorage.removeItem("headerTask");
        sessionStorage.removeItem("currentMemoId");
        sessionStorage.removeItem("toggleSidebar");
      }
    };

    firstRetrieveData();

    return () => {
      window.removeEventListener("popstate", (e) => {});
      window.removeEventListener("beforeunload", (e) => {});
    };
  }, [t]);

  useEffect(() => {
    getMyMemoStatus();
  }, []);

  // useEffect(() => {
  //   if (processQue?.length == 0) {
  //     fetchWorklist(taskGourp, null, true);
  //   } else {
  //     if (workListData) {
  //       setWorkListData([...workListData]);
  //     }
  //   }
  // }, [processQue]);

  useEffect(() => {
    updateWorklistToShow();
  }, [searchObject]);

  useEffect(() => {
    updateWorklistToShow();
  }, [workListData]);

  const fetchWorklist = async (
    task: TodoType,
    date?: [Moment?, Moment?] | null,
    silent: boolean = false
  ) => {
    if (!silent) {
      setIsCardLoading(true);
      checkSyncMemo();
    }

    if(abortController.current != null){
      abortController.current.abort();
    }
    abortController.current = new AbortController();

    let dateFrom = date ? date[0] : null;
    let dateTo = date ? date[1] : null;

    try {
      const dataJson: IGetWorklistRequest = {
        task: task,
        empId: userData?.EmployeeId?.toString(),
        iItemPerMore: 10000,
        CountMoreItem: 1,
        FilterDateFrom: dateFrom ? dateFrom.toISOString() : "",
        FilterDateTo: dateTo ? dateTo.toISOString() : "",
        UserPrincipalName: userData.Email,
      };
      setTaskGourp(task);
      const dataWorklist = await GetWorkListByTaskGroup(dataJson, abortController.current.signal);
      initialWorklistOption(dataWorklist);
    } catch (error) {
      console.error(error);
    }
    if (!silent) {
      setIsCardLoading(false);
    }
  };

  const initialWorklistOption = (worklist: ITodo[]) => {
    let _formTypeOption: any[] = [];
    let _formStatusOption: any[] = [];
    let _companiesOption: any[] = [];
    let _departmentsOption: any[] = [];

    const resultFormTypeOption = worklist.reduce((r, a) => {
      r[a.TemplateName] = r[a.TemplateName] || [];
      return r;
    }, Object.create(null));
    const resultFormStatusOption = worklist.reduce((r, a) => {
      r[a.Status] = r[a.Status] || [];
      return r;
    }, Object.create(null));
    const resultAllCompanyOption = worklist.reduce((r, a) => {
      r[a.CompanyName] = r[a.CompanyName] || [];
      return r;
    }, Object.create(null));
    const resultAllDepartmentOption = worklist.reduce((r, a) => {
      r[a.DepartmentName] = r[a.DepartmentName] || [];
      return r;
    }, Object.create(null));
    for (const [key, value] of Object.entries(resultFormStatusOption)) {
      _formStatusOption.push({
        label: t(key),
        value: key,
      });
    }
    for (const [key, value] of Object.entries(resultFormTypeOption)) {
      _formTypeOption.push({
        label: t(key),
        value: key,
      });
    }
    for (const [key, value] of Object.entries(resultAllCompanyOption)) {
      _companiesOption.push({
        label: t(key),
        value: key,
      });
    }
    for (const [key, value] of Object.entries(resultAllDepartmentOption)) {
      _departmentsOption.push({
        label: t(key),
        value: key,
      });
    }

    setDepartmentsOptions([..._departmentsOption]);
    setCompaniesOptions([..._companiesOption]);
    setFormStatusOptions([..._formStatusOption]);
    setFormTypeOptions([..._formTypeOption]);
    setWorkListData([...worklist]);
  };
  function toggleSideBar() {
    if (sidebarState) {
      setSidebarState(false);
      sessionStorage.setItem("toggleSidebar", "false");
    } else {
      setToggleDetail(false);
      setSidebarState(true);
      sessionStorage.setItem("toggleSidebar", "true");
    }
  }
  const onUpdateMemo = async (
    type: any,
    memoId: any,
    comment: string,
    waiting_for?: string,
    waiting_for_id?: number
  ) => {
    try {
      setWorkListData([
        ...(workListData?.filter((e) => e.MemoID != memoId) || []),
      ]);
      setIsCardLoading(true);
      // const _empData = empData.employeeData;
      let ddd = document.getElementsByTagName("body");
      ddd[0].style.overflow = "none";
      const requestBody = {
        Memoid: memoId,
        TemplateId: null,
        DocumentCode: null,
        SecretId: "",
        EmployeeId: userData.EmployeeId.toString(),
        actor: userData,
      };
      let _getMemoDetailById = await GetMemoDetail(requestBody);

      setGetMemoDetail(_getMemoDetailById);
      if (_getMemoDetailById) {
        let requestDetail = _getMemoDetailById.requestDetails;
        let listControlRunning = [];
        //set value
        requestDetail.memoDetail.comment = comment;
        requestDetail.memoDetail.actor = userData;
        requestDetail.memoDetail.waiting_for = waiting_for
          ? waiting_for
          : requestDetail.memoDetail.waiting_for;
        requestDetail.memoDetail.waiting_for_id = waiting_for_id
          ? waiting_for_id
          : requestDetail.memoDetail.waiting_for_id;

        if (!_getMemoDetailById.requestDetails.memoDetail.is_text_form) {
          //check control running

          if (requestDetail.memoDetail.template_desc) {
            let autoNumFormat: IAutoNumberAttibute = {
              formats: [],
              digit: 0,
              rowIndex: -1,
              colIndex: -1,
              showSymbol: false,
            };
            const templateDescJson = JSON.parse(
              requestDetail.memoDetail.template_desc
            );
            templateDescJson.items.map((item: any) =>
              item.layout.map((_layout: any) => {
                if (_layout.template.type === "tb") {
                  _layout.template.attribute.column.forEach((col: any) => {
                    col.isReadOnly = false;
                    col.isShow = true;
                  });
                } else {
                  _layout.isShow = true;
                  _layout.isReadOnly = false;
                }

                return _layout;
              })
            ),
              (autoNumFormat = detechAutonumberFormula(templateDescJson));

            if (autoNumFormat?.rowIndex !== -1) {
              const runningNumber = await CheckSaveAutonumber(
                requestDetail.memoDetail.template_id,
                templateDescJson,
                autoNumFormat
              );

              if (runningNumber) {
                listControlRunning.push(runningNumber);
              }
            }
            requestDetail.memoDetail.template_desc =
              JSON.stringify(templateDescJson);
          }
        }
        requestDetail["listControlRunning"] = listControlRunning;

        let _ActionMemoPage = await ActionMemoPage({
          MemoPage: {
            listApprovalDetails: requestDetail.listApprovalDetails,
            listFileAttachDetails: requestDetail.listFileAttachDetails,
            listFormName: requestDetail.listFormNames,
            listHistoryDetails: requestDetail.listHistoryDetails,
            listRefDocDetails: requestDetail.listRefDocDetails,
            memoDetail: requestDetail.memoDetail,
            listControlRunning: requestDetail.listControlRunning,
            // listFormName: requestDetail.listFormNames,
            // listRefDocDetails: _getMemoDetailById.refDocs,
          },
          Type: type,
        });

        if (_ActionMemoPage && !_ActionMemoPage.Message) {
          let desc: string = `${type} Success.`;
          let message: string = "Success";
          if (_ActionMemoPage === "ValidField") {
            desc = `${type} Error.`;
            message = _ActionMemoPage;
          }
          toggleAlert({
            description: desc,
            message: message,
            type: _ActionMemoPage === "ValidField" ? "error" : "success",
            // duration: 100000000,
          });
          //await Promise.all([fetchWorklist(), getMyMemoStatus()]);
          await getMyMemoStatus();
          await fetchWorklist(taskGourp);

          let ddd = document.getElementsByTagName("body");
          ddd[0].style.overflow = "none";
          setIsCardLoading(false);
          setToggleDetail(false);
        } else {
          toggleAlert({
            description: `${_ActionMemoPage.Message}`,
            message: `Error`,
            type: "error",
          });
          setIsCardLoading(false);
        }
      }
    } catch (error) {
      console.error("memo=>", error);
    }
  };
  const detechAutonumberFormula = (templateDesc: any) => {
    try {
      let _autoFormats: IAutoNumberAttibute = {
        formats: [],
        showSymbol: true,
        digit: 0,
        rowIndex: -1,
        colIndex: -1,
      };

      templateDesc.items.forEach((item: any, rowIdx: number) => {
        item.layout.forEach((layout: any, colIdx: number) => {
          if (layout.template.type === "an") {
            _autoFormats.formats = layout.template.attribute.formats;
            _autoFormats.showSymbol = !layout.template.alter.includes("|");
            _autoFormats.digit = layout.template.digit;
            _autoFormats.rowIndex = rowIdx;
            _autoFormats.colIndex = colIdx;
          }
        });
      });

      return _autoFormats;
    } catch (error) {
      return {
        formats: [],
        showSymbol: true,
        digit: 0,
        rowIndex: -1,
        colIndex: -1,
      };
    }
  };
  const CheckSaveAutonumber = async (
    templateId: Number,
    template_desc: any,
    autoNumFormat: IAutoNumberAttibute
  ) => {
    try {
      const rowIndex = autoNumFormat.rowIndex;
      const colIndex = autoNumFormat.colIndex;
      if (template_desc) {
        let value = template_desc.items[rowIndex].layout[colIndex].data.value;
        const autoTemp = template_desc.items[rowIndex].layout[colIndex];
        if (value) {
          const dd = value.split("-");
          const gg = dd.slice(0, dd.length - 1);
          let newRunning = -1;
          const requestBody = {
            Prefix: gg.join("-") + "-",
            Digit: autoTemp.template.digit,
            TemplateId: templateId,
            RunningNumber: dd.join("-"),
          };
          const checkAuto = await GetAutoNumber(requestBody);
          const checkNum = checkAuto.split("-");
          if (checkNum[0] !== "") {
            newRunning = checkNum[checkNum.length - 1];
            dd[dd.length - 1] = newRunning;
            requestBody.RunningNumber = newRunning;
          }
          return requestBody;
        }
      }
    } catch (error) {
      console.error("auto=>CheckSaveAutonumber=>error", error);
    }
  };
  const setContent = async (
    memoId: number,
    status: string,
    index: number,
    requestor: IUserModel
  ) => {
    // const _empData = empData.employeeData;

    const _memoDetail = await GetAllMemo({
      memoid: memoId,
      EmployeeId: requestor?.EmployeeId?.toString(),
      SecretId: "",
      actor: userData.EmployeeId,
    });

    if (_memoDetail.length > 0) {
      if (_memoDetail[0].Permission !== null) {
        if (_memoDetail[0].Permission.View === "F") {
          setIsShowPdfData(false);
        }
      }
    }

    if (currentIndex !== index) {
      setCurrentMemoId(Number(memoId));
      setCurrentIndex(index);
      setToggleDetail(true);
      sessionStorage.setItem("currentMemoId", memoId.toString());
    } else {
      setCurrentIndex(index);

      if (toggleDetail) {
        setCurrentMemoId(0);
        setToggleDetail(false);
        sessionStorage.setItem("currentMemoId", "0");
      } else {
        setCurrentMemoId(Number(memoId));
        setToggleDetail(true);
        sessionStorage.setItem("currentMemoId", memoId.toString());
      }
    }
    const actor = {
      EmployeeId: userData.EmployeeId,
    };
    // setToggleDetail(false);
    let _dataAttachfiles = await GetAllAttachFiles({ memoid: memoId });

    let _dataApprovals = await GetAllApprovals({ memoid: memoId });

    if (status !== "Draft") {
      let memoButton: IMemoButtonModel = {
        actor: {
          EmployeeId: userData.EmployeeId,
        },
        memoid: memoId,
      };
      let _dataMemobutton = await GetButtonMemoByMemoId(memoButton);
      setMenuButton(_dataMemobutton);
    } else {
      setMenuButton([]);
    }

    let _dataHistory = await GetAllHistory({
      Memoid: memoId,
      SecretId: "",
      actor: actor,
    });

    setOnDetailLoading(false);

    setSelectedCard((prevState) => ({
      ...prevState,
      approvals: _dataApprovals,
      attachfiles: _dataAttachfiles,
      requestor,
      MemoID: memoId,
      history: _dataHistory,
    }));

    sessionStorage.setItem(
      "selectedCard",
      selectedCard ? JSON.stringify(selectedCard) : "{}"
    );

    setSidebarState(false);
    if (currentIndex !== index) {
      setCurrentMemoId(Number(memoId));
      setCurrentIndex(index);
      setToggleDetail(true);
      sessionStorage.setItem("currentMemoId", memoId.toString());
    } else {
      setCurrentIndex(index);

      if (toggleDetail) {
        setCurrentMemoId(0);
        setToggleDetail(false);
        sessionStorage.setItem("currentMemoId", "0");
      } else {
        setCurrentMemoId(Number(memoId));
        setToggleDetail(true);
        sessionStorage.setItem("currentMemoId", memoId.toString());
      }
    }
  };

  const onSelectTask = (text: WorklistHeaderType) => {
    setTaskHeader(text);
    let date: [Moment?, Moment?] | null = null;
    let task: TodoType = "todo";
    if (text === "All Task Group") {
      task = "todo";
    } else if (text === "To Do List") {
      task = "todo";
    } else if (text === "Related List") {
      date = [moment(new Date()).subtract(1, "M"), moment(new Date())];
      task = "myrelate";
    } else if (text === "In Process") {
      task = "inprocess";
    } else if (text === "Completed") {
      task = "completed";
    } else if (text === "Cancelled") {
      task = "cancelled";
    } else if (text === "Rejected") {
      task = "rejected";
    }
    fetchWorklist(task, date);
    setCurrentPage(1);
    setDefaultTask(text);
    sessionStorage.setItem("task", task);
    sessionStorage.setItem("headerTask", text);
    sessionStorage.setItem("page", "1");
  };

  const onSelectChange = async (
    value: any | any[],
    formSelect: WorklistSerachType
  ) => {
    let rawObject = searchObject;

    if (formSelect) {
      rawObject[formSelect] = value;

      if (formSelect === "date") {
        fetchWorklist(taskGourp, value);
      } else {
        setSearchObject((prevState: any) => ({
          ...prevState,
          ...rawObject,
        }));

        sessionStorage.setItem("searchObject", JSON.stringify(searchObject));
      }

      setCurrentPage(1);
      sessionStorage.setItem("page", "1");
    }
  };

  const clearFilter = () => {
    setSearchObject({
      date: [],
      form: [],
      status: [],
      company: [],
      department: [],
      keyword: "",
    });
    sessionStorage.setItem("searchObject", JSON.stringify(searchObject));
    if (taskGourp == "myrelate") {
      fetchWorklist("myrelate", null);
    }
  };
  const paginate = (page: number) => {
    setCurrentPage(page);
    sessionStorage.setItem("page", page.toString());
  };
  const onSetItemPerPage = (numItem: any) => {
    setItemPerPage(numItem);
  };
  const filterStatusWorklistCard = (todo: ITodo, idx: number) => {
    return searchObject?.status.length > 0
      ? searchObject?.status.includes(todo.Status)
      : true;
  };
  const filterFormWorklistCard = (todo: ITodo, idx: number) => {
    return searchObject?.form.length > 0
      ? searchObject?.form.includes(todo.TemplateName)
      : true;
  };
  const filterCompanyWorklistCard = (todo: ITodo, idx: number) => {
    return searchObject?.company.length > 0
      ? searchObject?.company.includes(todo.CompanyName)
      : true;
  };
  const filterDepartmentWorklistCard = (todo: ITodo, idx: number) => {
    return searchObject?.department.length > 0
      ? searchObject?.department.includes(todo.DepartmentName)
      : true;
  };
  const filterKeywordWorklistCard = (todo: ITodo) => {
    const _keyword = searchObject?.keyword?.toUpperCase();
    const waitingFor: IUserModel = todo?.WaitingFor;
    const requestor: IUserModel = todo?.Requestor;

    return _keyword?.length > 0
      ? todo.DepartmentName?.toUpperCase().includes(_keyword) ||
          todo.TemplateName?.toUpperCase().includes(_keyword) ||
          todo.CompanyName?.toUpperCase().includes(_keyword) ||
          todo.Status?.toUpperCase().includes(_keyword) ||
          todo.DocumentNo?.toUpperCase().includes(_keyword) ||
          todo.Subject?.toUpperCase().includes(_keyword) ||
          (waitingFor &&
            waitingFor?.NameEn?.toUpperCase().includes(_keyword)) ||
          waitingFor?.NameTh.toUpperCase().includes(_keyword) ||
          (requestor && requestor?.NameEn?.toUpperCase().includes(_keyword)) ||
          requestor?.NameTh?.toUpperCase().includes(_keyword)
      : true;
  };

  const updateWorklistToShow = () => {
    const _worklist =
      workListData?.filter((todo, idx) => {
        let statusFilter = true;
        let companyFilter = true;
        let departmentFilter = true;
        let formFilter = true;
        let keywordFilter = true;

        if (processQue.includes(todo.MemoID)) {
          return false;
        }
        if (searchObject?.status.length > 0) {
          statusFilter = filterStatusWorklistCard(todo, idx);
        }
        if (searchObject?.company.length > 0) {
          companyFilter = filterCompanyWorklistCard(todo, idx);
        }
        if (searchObject?.department.length > 0) {
          departmentFilter = filterDepartmentWorklistCard(todo, idx);
        }
        if (searchObject?.form.length > 0) {
          formFilter = filterFormWorklistCard(todo, idx);
        }
        if (searchObject?.keyword.length > 0) {
          keywordFilter = filterKeywordWorklistCard(todo);
        }

        return (
          statusFilter &&
          companyFilter &&
          departmentFilter &&
          formFilter &&
          keywordFilter
        );
      }) || [];
    setWorklistToShow(_worklist);
  };

  const mapWorklistCard = (todo: ITodo, idx: number) => {
    return (
      <WorklistCardComponent
        // id={idx}
        dataWorklist={dtWorkListData}
        key={idx}
        data={todo}
        onUpdate={onUpdateMemo}
        currentMemoId={currentMemoId}
        setContent={setContent}
        index={idx}
        taskGourp={taskGourp}
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        setOnDetailLoading={setOnDetailLoading}
      />
    );
  };

  const firstRetrieveData = async () => {
    let actionBack = sessionStorage.getItem("action_back");
    let task = taskGourp;
    if (actionBack) {
      task = sessionStorage.getItem("task")
        ? (sessionStorage.getItem("task") as TodoType)
        : "todo";
    }
    await fetchWorklist(task);
    if (actionBack) {
      let search = sessionStorage.getItem("searchObject")
        ? JSON.parse(sessionStorage.getItem("searchObject")!)
        : searchObject;
      let page = sessionStorage.getItem("page")
        ? parseInt(sessionStorage.getItem("page")!)
        : 1;
      let selecteCard = sessionStorage.getItem("selectedCard")
        ? (JSON.parse(
            sessionStorage.getItem("selectedCard")!
          ) as IWorklistDetail)
        : selectedCard;
      let taskHeader: string = sessionStorage.getItem("headerTask")
        ? sessionStorage.getItem("headerTask")!
        : "To Do List";
      let currentMemoId = sessionStorage.getItem("currentMemoId")
        ? parseInt(sessionStorage.getItem("currentMemoId")!)
        : 0;
      let sidebar: boolean = sessionStorage.getItem("toggleSidebar")
        ? JSON.parse(sessionStorage.getItem("toggleSidebar")!)
        : false;

      setSearchObject(search);
      setCurrentPage(page);
      setSelectedCard(selecteCard);
      setDefaultTask((taskHeader as WorklistHeaderType) || "To Do List");
      setTaskHeader(taskHeader);
      setCurrentMemoId(currentMemoId);
      setSidebarState(sidebar);
    }
    sessionStorage.removeItem("action_back");
  };

  const isDiffPreviousQue = (current: number[], previous: number[]) => {
    return current.join(',') != previous.join(',')
  }

  useMemo(() => {
    if (
      processQue?.length == 0 &&
      isDiffPreviousQue(processQue, previousQue.current)
    ) {
      fetchWorklist(taskGourp, null, true);
    }
    previousQue.current = [...processQue];
  }, [processQue]);

  return (
    <div className="main-worklist">
      <AnimatePresence>
        <div className="main-container">
          {isCardLoading && (
            <div className="logo-loading">
              <img src={props.responeConfig?.pathLoading} alt="loading..." />
            </div>
          )}
          <div className="worklist-container">
            <div className="header-container">
              {sidebarState ? (
                <div className="mobile">
                  <div className="content filter-content">
                    <div className="worklist-filter-container">
                      <p className="clear-all-button" onClick={clearFilter}>
                        {t("Clear all")}
                      </p>
                      <div className="all-filter-container">
                        <WorkListSideBarElementUse
                          searchObject={searchObject}
                          onSelectChange={onSelectChange}
                          onSelectTask={onSelectTask}
                          formStatus={formStatusOptions}
                          allCompany={companiesOptions}
                          allDepartment={departmentsOptions}
                          formType={formTypeOptions}
                          defaultTask={defaultTask}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="button-container">
                <Button
                  type="text"
                  icon={<IoMenu size={28} />}
                  size="large"
                  onClick={toggleSideBar}
                  style={{ background: "transparent " }}
                />
                <TreeSelectNewRequest />
              </div>
              <div className="route-text-container">
                <p className="route-text">
                  {t("Worklist")} / {t(taskHeader)} ({worklistToShow?.length})
                </p>
              </div>
            </div>
            <div className="content">
              <div className="worklist-items-container">
                {sidebarState && (
                  <motion.div
                    className="desktop"
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -200 }}
                    transition={{ duration: 0.4 }}
                  >
                    <div className="content filter-content">
                      <div className="worklist-filter-container">
                        <p className="clear-all-button" onClick={clearFilter}>
                          {t("Clear all")}
                        </p>
                        <div className="all-filter-container">
                          <WorkListSideBarElementUse
                            searchObject={searchObject}
                            onSelectChange={onSelectChange}
                            onSelectTask={onSelectTask}
                            formStatus={formStatusOptions}
                            allCompany={companiesOptions}
                            allDepartment={departmentsOptions}
                            formType={formTypeOptions}
                            defaultTask={defaultTask}
                          />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
                <div className="worklist-cards-container">
                  <div className="item-per-page-container">
                    <span className="text-show">{t("Show")}</span>
                    <InputNumber
                      type={"number"}
                      size="small"
                      max={100000}
                      value={itemPerPage}
                      onChange={onSetItemPerPage}
                    />
                    <span className="text-items-per-page">
                      {t("items per page")}
                    </span>
                  </div>
                  <div className="cards-container">
                    {worklistToShow
                      ?.slice(indexOfFirstPost, indexOfLastPost)
                      ?.map(mapWorklistCard)}
                  </div>
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={itemPerPage ? Number(itemPerPage) : 1}
                    total={worklistToShow?.length}
                    onChange={paginate}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}></div>
            <FooterComponents />
          </div>
          {toggleDetail && (
            <DetailContentScreen
              setButtonType={setButtonType}
              buttonType={buttonType}
              data={selectedCard}
              isShowPdfData={isShowPdfData}
              menuButton={menuButton}
              onUpdate={onUpdateMemo}
              onDetailLoading={onDetailLoading}
            />
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};
export default withPerMission(WorkListScreen);
